import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  delSiteEmailDetails,
  delSitePaymentDetails,
  delSiteShippingDetails,
  delSiteSMSDetails,
  getMenuSettings,
  getSiteDetails,
  getSiteEmailDetails,
  getSitePaymenetDetails,
  getSiteShippingDetails,
  getSiteSMSDetails,
  setSiteDetails,
  setSiteEmailDetails,
  setSitePaymentDetails,
  setSiteShippingDetails,
  setSiteSMSDetails,
  updateMenuSettings,
  updateSiteDetails,
} from "apis/restApis";
import {
  STATUSES,
  TEmailDetails,
  TGetParams,
  TMenuDetails,
  Toast,
  TOAST_CONSTANTS,
  TPaymentDetails,
  TShippingDetails,
  TSiteDetails,
  TSMSDetails,
} from "utils";

const initialState = {
  payment_details: [] as TPaymentDetails[],
  shipping_details: [] as TShippingDetails[],
  sms_details: [] as TSMSDetails[],
  email_settings: {} as {
    config_type: string[];
    data: TEmailDetails[];
  },
  site_management_details: {} as TSiteDetails,
  menu_settings: {} as TMenuDetails,
  status: STATUSES.IDLE as string,
  error: null as string | null,
};

export const fetchSiteDetails = createAsyncThunk(
  "site_management/fetchSiteDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSiteDetails();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewSiteDetails = createAsyncThunk(
  "site_management/addNewSiteDetails",
  async (siteDetailsParams, { rejectWithValue }) => {
    try {
      const response = await setSiteDetails(siteDetailsParams);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingSiteDetails = createAsyncThunk(
  "site_management/updateExistingSiteDetails",
  async (
    { siteDetailsParams }: { siteDetailsParams: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await updateSiteDetails(siteDetailsParams);
      dispatch(fetchSiteDetails());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSitePaymentDetails = createAsyncThunk(
  "site_management/fetchSitePaymentDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSitePaymenetDetails();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewSitePaymentDetails = createAsyncThunk(
  "site_management/addNewSitePaymentDetails",
  async (
    { paymentDetailsParams }: { paymentDetailsParams: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await setSitePaymentDetails(paymentDetailsParams);
      dispatch(fetchSitePaymentDetails());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSitePaymentDetails = createAsyncThunk(
  "site_management/deleteSitePaymentDetails",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delSitePaymentDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSiteShippingDetails = createAsyncThunk(
  "site_management/fetchSiteShippingDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSiteShippingDetails();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewSiteShippingDetails = createAsyncThunk(
  "site_management/addNewSiteShippingDetails",
  async (
    { shippingDetailsParams }: { shippingDetailsParams: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await setSiteShippingDetails(shippingDetailsParams);
      dispatch(fetchSiteShippingDetails());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSiteShippingDetails = createAsyncThunk(
  "site_management/deleteSiteShippingDetails",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delSiteShippingDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSiteEmailDetails = createAsyncThunk(
  "site_management/fetchSiteEmailDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSiteEmailDetails();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewSiteEmailDetails = createAsyncThunk(
  "site_management/addNewSiteEmailDetails",
  async (
    { emailDetailsParams }: { emailDetailsParams: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await setSiteEmailDetails(emailDetailsParams);
      dispatch(fetchSiteEmailDetails());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSiteEmailDetails = createAsyncThunk(
  "site_management/deleteSiteEmailDetails",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delSiteEmailDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSiteSMSDetails = createAsyncThunk(
  "site_management/fetchSiteSMSDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSiteSMSDetails();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewSiteSMSDetails = createAsyncThunk(
  "site_management/addNewSiteSMSDetails",
  async (
    { smsDetailsParams }: { smsDetailsParams: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await setSiteSMSDetails(smsDetailsParams);
      dispatch(fetchSiteSMSDetails());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSiteSMSDetails = createAsyncThunk(
  "site_management/deleteSiteSMSDetails",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await delSiteSMSDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchsiteMenuSettings = createAsyncThunk(
  "site_management/fetchsiteMenuSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getMenuSettings();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateExistingSiteMenuSettings = createAsyncThunk(
  "site_management/updateExistingSiteMenuSettings",
  async (
    { menuSettingsParams }: { menuSettingsParams: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await updateMenuSettings(menuSettingsParams);
      dispatch(fetchsiteMenuSettings());
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const handleDeleteDetails = (array, id, updatedItem) => {
  const index = array.findIndex((item) => item.id === id);
  if (index !== -1) {
    array[index] = updatedItem;
  }
};

const siteManagementSlice = createSlice({
  name: "site_management",
  initialState,
  reducers: {
    setSiteMenuSettings: (state, action) => {
      state.menu_settings = action.payload;
    },
    resetSiteManagementState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      // Handle fetch actions
      .addCase(fetchSiteDetails.fulfilled, (state, action: any) => {
        state.site_management_details = action.payload.result;
      })
      .addCase(fetchSiteEmailDetails.fulfilled, (state, action: any) => {
        state.email_settings = action.payload.result;
      })
      .addCase(fetchSiteSMSDetails.fulfilled, (state, action: any) => {
        state.sms_details = action.payload.result.results;
      })
      .addCase(fetchSitePaymentDetails.fulfilled, (state, action: any) => {
        state.payment_details = action.payload.result.results;
      })
      .addCase(fetchSiteShippingDetails.fulfilled, (state, action: any) => {
        state.shipping_details = action.payload.result.results;
      })
      .addCase(fetchsiteMenuSettings.fulfilled, (state, action: any) => {
        state.menu_settings = action.payload.result;
      })

      // Handle delete actions
      .addCase(deleteSiteEmailDetails.fulfilled, (state, action: any) => {
        handleDeleteDetails(
          state.email_settings.data,
          action.meta.arg,
          action.payload.result
        );
      })
      .addCase(deleteSiteSMSDetails.fulfilled, (state, action: any) => {
        handleDeleteDetails(
          state.sms_details,
          action.meta.arg,
          action.payload.result
        );
      })
      .addCase(deleteSitePaymentDetails.fulfilled, (state, action: any) => {
        handleDeleteDetails(
          state.payment_details,
          action.meta.arg,
          action.payload.result
        );
      })
      .addCase(deleteSiteShippingDetails.fulfilled, (state, action: any) => {
        handleDeleteDetails(
          state.shipping_details,
          action.meta.arg,
          action.payload.result
        );
      })

      // Handle common matchers
      .addMatcher(
        (action) => /^site_management\/[^/]+\/fulfilled$/.test(action.type),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          updateExistingSiteDetails.fulfilled,
          updateExistingSiteMenuSettings.fulfilled,
          addNewSiteEmailDetails.fulfilled,
          deleteSiteEmailDetails.fulfilled,
          addNewSiteSMSDetails.fulfilled,
          deleteSiteSMSDetails.fulfilled,
          addNewSitePaymentDetails.fulfilled,
          deleteSitePaymentDetails.fulfilled,
          addNewSiteShippingDetails.fulfilled,
          deleteSiteShippingDetails.fulfilled
        ),
        (_, action: any) => {
          Toast(action.payload.message, TOAST_CONSTANTS.SUCCESS);
        }
      )
      .addMatcher(
        isAnyOf(
          fetchSitePaymentDetails.pending,
          fetchSiteShippingDetails.pending,
          fetchSiteEmailDetails.pending,
          fetchSiteSMSDetails.pending,
          fetchsiteMenuSettings.pending,
          fetchSiteDetails.pending,
          updateExistingSiteDetails.pending,
          updateExistingSiteMenuSettings.pending,
          addNewSitePaymentDetails.pending,
          addNewSiteEmailDetails.pending,
          addNewSiteSMSDetails.pending,
          addNewSiteShippingDetails.pending
        ),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        (action) => /^site_management\/[^/]+\/rejected$/.test(action.type),
        (state, action) => {
          state.status = STATUSES.ERROR;
          state.error = action.payload;
          Toast(action.payload, TOAST_CONSTANTS.ERROR);
        }
      );
  },
});

export const {
  setSiteMenuSettings,
  resetSiteManagementState,
} = siteManagementSlice.actions;

export default siteManagementSlice.reducer;
