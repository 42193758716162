import { Button, Modal, MultiSelect } from "components/core-components";
import { CustomInput } from "components/group-components/CustomInput";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  generateOtpForMasterPassword,
  verifyOtpForMasterPassword,
} from "store/commonSlice";
import {
  fetchAuthorizedEmployee,
  resetEmployeeState,
} from "store/ManageEmployee/employeeSlice";
import { getModules } from "store/ManageRoles/moduleSlice";
import {
  BUTTON_CONSTANTS,
  capitalizeWithSeperator,
  debounce,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  MasterPasswrodSchema,
  STATUSES,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  module: string;
  module_name: string;
  sub_module_id: number;
  store_id: number;
  extra_id: number;
  extra_data?: any;
  permission: "view" | "add" | "edit" | "delete" | "authorize";
  onSuccess?: (props: any) => void;
  onFailure?: () => void;
}

const MasterPasswordModal = ({
  module,
  module_name,
  sub_module_id,
  store_id,
  extra_id,
  extra_data,
  permission,
  modal,
  setModal,
  onSuccess: parentOnSuccess,
}: IProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    auth: { permissions },
    common: {
      current_page,
      total_items,
      total_pages,
      page_size,
      status: commonState,
    },
    employee: { employees: employeeData },
    module: { modules },
  } = useAppSelector((state) => state.root);

  const initialValues = {
    selected_employee: [],
    otp: "",
  };

  const [query, setQuery] = useState<string>(GENERAL_CONSTANTS.EMPTY_STRING);

  const [otpSent, setOtpSent] = useState(0);

  const [otpTimer, SetOtpTimer] = useState<{
    seconds: number;
    minutes: number;
  }>({
    minutes: 0,
    seconds: 0,
  });

  const propsRef = useRef<
    FormikProps<{
      selected_employee: any[];
      otp: string;
    }>
  >(null);

  const update_page = (pageNo: number) => {
    modules?.length === 0 && dispatch(getModules());
    if (sub_module_id && permission && store_id) {
      dispatch(
        fetchAuthorizedEmployee({
          pageNo: pageNo,
          query,
          authParams: {
            action: permission,
            module_id: sub_module_id
              ? undefined
              : permissions?.find((p) => p.module_name === module_name)?.id,
            sub_module_id: sub_module_id,
            store_id: store_id,
          },
        })
      );
    }
  };

  const getEmployeeOptions = useMemo(() => {
    return employeeData.map((employee) => {
      return {
        label: employee?.first_name + " " + employee?.last_name,
        value: employee?.id,
      };
    });
  }, [employeeData?.length]);

  const requestOTP = (values) => {
    dispatch(
      generateOtpForMasterPassword({
        params: {
          employee_id: values.selected_employee?.[0]?.value,
          module: permissions?.find((p) => p.module_name === module_name)?.id,
          sub_module: sub_module_id,
          extra_id: extra_id,
        },
        onSuccess: () => {
          setOtpSent((prev) => prev + 1);
          SetOtpTimer({
            minutes: 2,
            seconds: 0,
          });
        },
        type: "generate-master-password-otp",
      })
    );
  };

  const verifyOTP = (values) => {
    dispatch(
      verifyOtpForMasterPassword({
        otp: Number(values.otp),
        onSuccess: () => {
          parentOnSuccess({
            module: module,
            data: extra_data,
            navigate: navigate,
          });
          setModal(false);
        },
        master_pass_type: module,
        type: "verify-master-password-otp",
      })
    );
  };

  const setEmployeeQuery = (value: string) => {
    setQuery(value);
  };

  const filterEmployee = useCallback(debounce(setEmployeeQuery), []);

  const { resetState, nextClickHandler } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTimer.seconds > 0) {
        SetOtpTimer({
          ...otpTimer,
          seconds: otpTimer.seconds - 1,
        });
      }

      if (otpTimer.seconds === 0) {
        if (otpTimer.minutes === 0) {
          clearInterval(interval);
        } else {
          SetOtpTimer({
            seconds: 59,
            minutes: otpTimer.minutes - 1,
          });
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    return () => {
      resetState();
      dispatch(resetEmployeeState());
    };
  }, []);

  return (
    <Modal
      modalClassNames="w-100"
      title={`Authoriztion for ${capitalizeWithSeperator(module, "_")}`}
      showModal={modal}
      onClickHandler={() => {
        setOtpSent(0);
        SetOtpTimer({
          minutes: 0,
          seconds: 0,
        });
        setModal(false);
        if (propsRef.current) {
          propsRef.current.resetForm();
        }
      }}
      footer={
        <div className="row">
          {otpSent === 0 ? (
            <Button
              text={"Request OTP"}
              type={BUTTON_CONSTANTS.BUTTON}
              onClickHandler={() => propsRef.current.submitForm()}
              btnClassNames="btn btn-primary"
              loading={
                commonState.state === STATUSES.LOADING &&
                commonState.type === "generate-master-password-otp"
              }
            />
          ) : otpSent > 0 ? (
            <Button
              text={`Verify`}
              type={BUTTON_CONSTANTS.BUTTON}
              onClickHandler={() => propsRef.current.submitForm()}
              btnClassNames="btn btn-primary w-50 mb-10"
              loading={
                commonState.state === STATUSES.LOADING &&
                commonState.type === "verify-master-password-otp"
              }
            />
          ) : null}
          {otpSent > 0 ? (
            <span className="px-0 my-3">
              Not received your code?{" "}
              <Link
                to={"#"}
                onClick={() => {
                  if (!(otpTimer.seconds > 0 || otpTimer.minutes > 0)) {
                    propsRef.current.setFieldValue("otp", "");
                    requestOTP(propsRef.current.values);
                  }
                }}
              >
                {otpTimer.seconds > 0 || otpTimer.minutes > 0
                  ? `${
                      otpTimer.minutes < 10
                        ? `0${otpTimer.minutes}`
                        : otpTimer.minutes
                    }:${
                      otpTimer.seconds < 10
                        ? `0${otpTimer.seconds}`
                        : otpTimer.seconds
                    }`
                  : "Resend OTP"}
              </Link>
            </span>
          ) : null}
        </div>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (values.otp) {
            verifyOTP(values);
            return;
          }
          requestOTP(values);
        }}
        validationSchema={MasterPasswrodSchema({ otpSent: otpSent > 0 })}
      >
        {(props) => {
          propsRef.current = props;
          return (
            <Form>
              <div className="row">
                <div className="col-12 mt-2">
                  <MultiSelect
                    name="selected_employee"
                    label="Please Select Employee"
                    placeholder="Please Select Employee"
                    isRequired={true}
                    isLoading={
                      commonState.state === STATUSES.LOADING &&
                      commonState.type === "fetch-employee"
                    }
                    options={getEmployeeOptions}
                    onChangeHandler={(value, action) => {
                      props.setFieldValue(
                        "selected_employee",
                        value ? [value] : []
                      );
                    }}
                    onFocusHandler={() => {
                      employeeData?.length === 0 &&
                        dispatch(
                          fetchAuthorizedEmployee({
                            pageNo: 1,
                            query,
                            authParams: {
                              action: permission,
                              module_id: sub_module_id
                                ? undefined
                                : permissions?.find(
                                    (p) => p.module_name === module_name
                                  )?.id,
                              sub_module_id: sub_module_id,
                              store_id: store_id,
                            },
                          })
                        );
                    }}
                    onInputChangeHandler={(value) => {
                      filterEmployee(value);
                    }}
                    onEndReached={() => {
                      if (total_pages > current_page) {
                        nextClickHandler();
                      }
                    }}
                  />
                  <ErrorMessage
                    name={"selected_employee"}
                    component={FORM_CONSTANTS.ERROR_PARENT}
                    className={FORM_CONSTANTS.ERROR}
                  />
                </div>
                {otpSent > 0 && (
                  <div className="col-12 mt-2">
                    <CustomInput
                      name="otp"
                      value={props.values.otp}
                      label="Enter OTP"
                      placeholder="Enter OTP"
                      type={FORM_CONSTANTS.NUMBER}
                      isRequired={true}
                    />
                    <ErrorMessage
                      name={"otp"}
                      component={FORM_CONSTANTS.ERROR_PARENT}
                      className={FORM_CONSTANTS.ERROR}
                    />
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export { MasterPasswordModal };
