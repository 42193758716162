import { CustomMultiSelect, Modal } from "components/core-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { clearProducts } from "store/Ecommerce";
import {
  getProductsForSpecificStore,
  getProductsOnScan,
} from "store/ManageProducts/productSlice";
import {
  constructProductOptions,
  containsNumbers,
  debounce,
  GENERAL_CONSTANTS,
  isBarcode,
  POS_HEADER_CONSTANTS,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
  TPOSHeaderQuery,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  focusRef: React.MutableRefObject<any>;
  batchModal: boolean;
  setBatchModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddProductToGroup = ({
  focusRef,
  modal,
  setModal,
  batchModal,
  setBatchModal,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {
    branch: { selectedStore },
    cart: { productGroupDetails },
    product: { products: productData, status: productStatus },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState<{
    query: string;
    type: TPOSHeaderQuery;
  }>({
    query: GENERAL_CONSTANTS.EMPTY_STRING,
    type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
  });

  const toggleBatchModal = (value: boolean) => {
    setModal(false);
    setBatchModal(value);
  };

  const getProductOptions: any[] = useMemo(() => {
    return constructProductOptions(productData);
  }, [productData?.length]);

  const searchValues = (query) => {
    if (!selectedStore) {
      // If store is not selected
      return Toast(
        POS_HEADER_CONSTANTS.SELECT_STORE_MESSAGE,
        TOAST_CONSTANTS.ERROR
      );
    }
    if (!isBarcode(query?.query?.trim()) && query?.query?.trim()?.length >= 3) {
      // If query is not barcode and length is greater than 3

      dispatch(
        getProductsForSpecificStore({
          pageNo: 1,
          clearState: () => {
            setModal(false);
            setQuery({
              type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
              query: GENERAL_CONSTANTS.EMPTY_STRING,
            });
          },
          query: query?.query?.trim(),
          store: selectedStore,
          pos: true,
        })
      );
    } else if (
      isBarcode(query?.query?.trim()) &&
      query?.query?.trim()?.length > 3
    ) {
      dispatch(
        getProductsOnScan({
          code: query?.query?.trim(),
          store: selectedStore,
          pos: true,
          toggleBatchModal: toggleBatchModal,
          clearState: () => {
            setModal(false);
            setQuery({
              type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
              query: GENERAL_CONSTANTS.EMPTY_STRING,
            });
          },
          addToProductGroup: productGroupDetails,
        })
      );
      // If query is barcode and length is greater than 3

      //Clear options after search
      dispatch(clearProducts());
    }
  };

  //Debounce function for search
  const optimizeSearch = useCallback(debounce(searchValues), [selectedStore]);

  return (
    <Modal
      modalClassNames="w-100"
      title={"Add Product"}
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
      }}
    >
      <div className="row">
        <CustomMultiSelect
          autoFocus
          name="product-search"
          selectRef={focusRef}
          disabled={!selectedStore}
          tabIndex={1}
          select={false}
          isLoading={productStatus === STATUSES.LOADING}
          placeholder={POS_HEADER_CONSTANTS.SCAN_PRODUCT_PLACEHOLDER}
          options={getProductOptions}
          value={null}
          onChangeHandler={(e) => {
            setQuery &&
              setQuery({
                query: GENERAL_CONSTANTS.EMPTY_STRING,
                type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
              });
            if (e && e.value && e.type === "product") {
              dispatch(
                getProductsForSpecificStore({
                  store: selectedStore,
                  pos: true,
                  product: e.value,
                  toggleBatchModal: toggleBatchModal,
                })
              );
            } else if (e && e.value && e.type === "productGroup") {
              Toast(
                "Cannot add hamper inside another hamper",
                TOAST_CONSTANTS.ERROR
              );
              return;
            }
            dispatch(clearProducts());
          }}
          //On key down event for enter key
          onKeyDownHandler={(e) => {
            if (e.key === GENERAL_CONSTANTS.ENTER) {
              // e.preventDefault();
              e.stopPropagation();
              if (isBarcode(query.query)) {
                dispatch(
                  getProductsOnScan({
                    code: query.query,
                    store: selectedStore,
                    pos: true,
                    toggleBatchModal: toggleBatchModal,
                    clearState: () => {
                      setModal(false);
                      setQuery({
                        type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
                        query: GENERAL_CONSTANTS.EMPTY_STRING,
                      });
                    },
                    addToProductGroup: productGroupDetails,
                  })
                );
              } else {
                optimizeSearch(query);
              }
            }
          }}
          inputValue={
            query.type === POS_HEADER_CONSTANTS.PRODUCT_QUERY
              ? query.query
              : GENERAL_CONSTANTS.EMPTY_STRING
          }
          onInputChangeHandler={(e) => {
            if (e.length === 0) {
              dispatch(clearProducts());
            }
            // if (!isBarcode(e) && e.length >= 3) {
            if (!containsNumbers(e.substring(0, 3))) {
              optimizeSearch({
                query: e,
                type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
              });
            }
            setQuery({
              query: e,
              type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
            });
          }}
          menuCloseHandler={() => {
            dispatch(clearProducts());
          }}
        />
      </div>
    </Modal>
  );
};

export { AddProductToGroup };
