import { CustomInput } from "components/group-components/CustomInput";
import { Button, Container, Modal } from "components/core-components";
import { Form, Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import {
  BUTTON_CONSTANTS,
  IReturnItem,
  Toast,
  to2Decimal,
  useAppSelector,
} from "utils";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;

  refundItems: IReturnItem[];
  setRefundItems: React.Dispatch<React.SetStateAction<IReturnItem[]>>;
}

const RefundModal = ({
  modal,
  setModal,
  refundItems,
  setRefundItems,
}: IProps) => {
  const {
    cart: { currentReturn },
  } = useAppSelector((state) => state.root);

  const PropsRef = useRef<FormikProps<any>>();

  const findRefundIndex = () => {
    return (
      Object.keys(currentReturn).length > 0 &&
      refundItems.findIndex((cartItem) => {
        if (cartItem.batch === currentReturn.batch.id) {
          if (currentReturn.product_group !== null) {
            return (
              cartItem?.product_group?.id === currentReturn?.product_group?.id
            );
          } else if (cartItem.product_group === null) {
            return true;
          }
        } else {
          return false;
        }
      })
    );
  };

  const updateValues = (quantity: number, remarks: string) => {
    const igst =
      (currentReturn.igst /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const cgst =
      (currentReturn.cgst /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const sgst =
      (currentReturn.sgst /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const cess =
      (currentReturn.cess /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    const total_tax = igst + cgst + sgst + cess;

    const total_discount =
      (currentReturn.total_discount /
        (currentReturn.returned
          ? currentReturn.originalQuantity
          : currentReturn.quantity)) *
      quantity;

    return {
      order_product_id: currentReturn.id,
      product_group: currentReturn.product_group
        ? currentReturn.product_group
        : null,
      batch: currentReturn.batch.id,
      price_with_discount: to2Decimal(
        currentReturn.payable_amount / currentReturn.quantity
      ),
      price: to2Decimal(
        currentReturn.total_amount /
          (currentReturn.returned
            ? currentReturn.originalQuantity
            : currentReturn.quantity)
      ),
      product: currentReturn.product.id,
      quantity: quantity,
      remarks: remarks,
      igst: to2Decimal(igst),
      cgst: to2Decimal(cgst),
      sgst: to2Decimal(sgst),
      cess: to2Decimal(cess),
      discount_type: currentReturn.discount_type,
      discount_value: currentReturn.discount_value,
      total_tax: to2Decimal(total_tax),
      total_discount: to2Decimal(total_discount),
      total_amount:
        (currentReturn.payable_amount /
          (currentReturn.returned
            ? currentReturn.originalQuantity
            : currentReturn.quantity)) *
        quantity,
    };
  };

  const handleSubmit = (values, actions) => {
    if (values.quantity > currentReturn.quantity) {
      Toast(
        "Refund Quantity cannot be greater than the quantity of the product in the cart",
        "info"
      );
      return;
    } else if (values.quantity < 0) {
      Toast("Refund Quantity cannot be less than 0", "info");
      return;
    } else {
      const index = findRefundIndex();
      if (index !== -1) {
        const currentrefundItems = [...refundItems];
        currentrefundItems[index] = updateValues(
          values.quantity,
          values.remarks
        );
        setRefundItems(currentrefundItems);
      } else {
        setRefundItems([
          ...refundItems,
          updateValues(values.quantity, values.remarks),
        ]);
      }
      setModal(false);
    }
  };

  return (
    <Modal
      centered={true}
      title="Return Item"
      showModal={modal}
      onClickHandler={() => {
        setModal(false);
      }}
    >
      <Container>
        <Formik
          enableReinitialize={true}
          initialValues={
            findRefundIndex() !== -1
              ? {
                  quantity: refundItems[findRefundIndex()].quantity,
                  remarks: refundItems[findRefundIndex()].remarks,
                }
              : {
                  quantity: 0,
                  remarks: "",
                }
          }
          onSubmit={handleSubmit}
        >
          {(props) => {
            PropsRef.current = props;
            return (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <CustomInput
                      min={0}
                      max={currentReturn?.quantity}
                      step={0.001}
                      type="number"
                      name="quantity"
                      value={props.values.quantity}
                      label="Return Quantity"
                      placeholder="Enter Quantity to be returned"
                    />
                  </div>
                  <div className="col-12">
                    <CustomInput
                      type="text"
                      name="remarks"
                      value={props.values.remarks}
                      label="Product Return Remarks"
                      placeholder="Enter Remarks"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Button
                      text="Return"
                      btnClassNames="btn btn-dark"
                      type={BUTTON_CONSTANTS.SUBMIT}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Modal>
  );
};

export { RefundModal };
