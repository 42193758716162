import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  FileUpload,
  Loader,
  MultiSelect,
} from "components";
import { ScrollToFieldError } from "components/ScollToFieldError";
import { ErrorMessage } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { uploadImage } from "store/commonSlice";
import { fetchPages } from "store/Ecommerce";
import {
  SITE_MANAGEMENT_CONSTANTS,
  FORM_CONSTANTS,
  GENERAL_CONSTANTS,
  useAppDispatch,
  useAppSelector,
  STATUSES,
  debounce,
  usePagination,
  S3_FOLDER_CONSTANTS,
} from "utils";

const SiteGeneralDetails = ({ props }) => {
  const dispatch = useAppDispatch();

  const {
    root: {
      common: {
        current_page,
        total_items,
        total_pages,
        page_size,
        status: commonStatus,
      },
    },
    ecommerce: {
      siteManagement: { status },
      pages: { pages },
    },
  } = useAppSelector((state) => state);

  const [showPassword, setShowPassword] = useState(false);

  const [query, setQuery] = useState("");

  const updateImage = (e, fieldName, value) => {
    props.setFieldValue(
      fieldName,
      Object.assign(e?.target?.files?.[0], {
        preview: value,
      })
    );
  };

  const getPagesOptions = useMemo(() => {
    return pages.map((page) => ({
      value: page.id,
      label: page.page_name,
    }));
  }, [pages.length]);

  const update_page = (pageNo) => {
    if (query) {
      dispatch(fetchPages({ active: true, query: query }));
    }
  };

  const searchValues = (values) => {
    setQuery(values);
  };

  const optimizeSearch = useCallback(debounce(searchValues), []);

  const { resetState } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  return (
    <React.Fragment key={SITE_MANAGEMENT_CONSTANTS.TABS[0]}>
      <ScrollToFieldError />
      {status === STATUSES.LOADING && !props.isSubmitting && (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}
      <div className="col-12">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title">
                {SITE_MANAGEMENT_CONSTANTS.GENERAL_HEADER}
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_NAME}
                  label={SITE_MANAGEMENT_CONSTANTS.SITE_NAME_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.SITE_NAME_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_NAME}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_URL}
                  label={SITE_MANAGEMENT_CONSTANTS.SITE_URL_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.SITE_URL_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_URL}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.NUMBER}
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_NUMBER}
                  label={SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_NUMBER_LABEL}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_NUMBER_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_NUMBER}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.EMAIL}
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_EMAIL}
                  label={SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_EMAIL_LABEL}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_EMAIL_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_CONTACT_EMAIL}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <FileUpload
                  value={null}
                  name={SITE_MANAGEMENT_CONSTANTS.ICON}
                  label={SITE_MANAGEMENT_CONSTANTS.ICON_LABEL}
                  hideInput={
                    (props.values?.icon || props.values?.icon?.preview || "")
                      .length > 0
                  }
                  preview={
                    props.values?.icon || props.values?.icon?.preview || ""
                  }
                  multipleUpload={false}
                  onChange={(e) => {
                    e?.target?.files?.length > 0 &&
                      dispatch(
                        uploadImage({
                          params: {
                            folder_name: `${S3_FOLDER_CONSTANTS.SITE_SETTING}/icon`,
                            file_name: e?.target?.files?.[0]?.name,
                            file: e?.target?.files?.[0],
                          },
                          updatePropsValues: (value) =>
                            updateImage(
                              e,
                              SITE_MANAGEMENT_CONSTANTS.ICON,
                              value
                            ),
                        })
                      );
                  }}
                  deleteImage={() => {
                    props.setFieldValue(SITE_MANAGEMENT_CONSTANTS.ICON, "");
                  }}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.ICON}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <FileUpload
                  value={null}
                  name={SITE_MANAGEMENT_CONSTANTS.FAVICON}
                  label={SITE_MANAGEMENT_CONSTANTS.FAVICON_LABEL}
                  hideInput={
                    (
                      props.values?.favicon ||
                      props.values?.favicon?.preview ||
                      ""
                    ).length > 0
                  }
                  preview={
                    props.values?.favicon ||
                    props.values?.favicon?.preview ||
                    ""
                  }
                  multipleUpload={false}
                  onChange={(e) => {
                    dispatch(
                      uploadImage({
                        params: {
                          folder_name: `${S3_FOLDER_CONSTANTS.SITE_SETTING}/favicon`,
                          file_name: e.target.files[0].name,
                          file: e.target.files[0],
                        },
                        updatePropsValues: (value) =>
                          updateImage(
                            e,
                            SITE_MANAGEMENT_CONSTANTS.FAVICON,
                            value
                          ),
                      })
                    );
                  }}
                  deleteImage={() => {
                    props.setFieldValue(SITE_MANAGEMENT_CONSTANTS.FAVICON, "");
                  }}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.FAVICON}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <MultiSelect
                  showNoOptionMsg
                  select={false}
                  name={SITE_MANAGEMENT_CONSTANTS.FRONT_PAGE}
                  label={SITE_MANAGEMENT_CONSTANTS.FRONT_PAGE_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.FRONT_PAGE_PLACEHOLDER}
                  defaultValue={
                    getPagesOptions.find(
                      (page) => page.value === props.values.front_page
                    ) || { value: "", label: "" }
                  }
                  options={getPagesOptions}
                  isLoading={
                    commonStatus.state === STATUSES.LOADING &&
                    commonStatus.type === "fetch-pages"
                  }
                  onFocusHandler={() => {
                    dispatch(fetchPages({ active: true, query: "" }));
                  }}
                  onInputChangeHandler={(value) => {
                    value.length > 0 && optimizeSearch(value);
                  }}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.FRONT_PAGE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <MultiSelect
                  name={SITE_MANAGEMENT_CONSTANTS.PUBLISH_WEBSITE}
                  label={SITE_MANAGEMENT_CONSTANTS.PUBLISH_WEBSITE_LABEL}
                  options={GENERAL_CONSTANTS.YES_NO_OPTIONS}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.PUBLISH_WEBSITE_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.PUBLISH_WEBSITE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <MultiSelect
                  name={SITE_MANAGEMENT_CONSTANTS.NO_FOLLOW_NO_INDEX}
                  label={SITE_MANAGEMENT_CONSTANTS.NO_FOLLOW_NO_INDEX_LABEL}
                  options={GENERAL_CONSTANTS.YES_NO_OPTIONS}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.NO_FOLLOW_NO_INDEX}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <MultiSelect
                  name={SITE_MANAGEMENT_CONSTANTS.ROBOTS_ENABLED}
                  label={SITE_MANAGEMENT_CONSTANTS.ROBOTS_ENABLED_LABEL}
                  options={GENERAL_CONSTANTS.YES_NO_OPTIONS}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.ROBOTS_ENABLED}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-12">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title">
                {SITE_MANAGEMENT_CONSTANTS.EMAIL_HEADER}
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.USERNAME}`}
                  label={SITE_MANAGEMENT_CONSTANTS.USERNAME_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.USERNAME_PLACEHOLDER}
                />
                <ErrorMessage
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.USERNAME}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  isPass={true}
                  setShowPass={setShowPassword}
                  showPass={showPassword}
                  label={SITE_MANAGEMENT_CONSTANTS.PASSWORD_LABEL}
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.PASSWORD}`}
                  type={
                    showPassword ? FORM_CONSTANTS.TEXT : FORM_CONSTANTS.PASSWORD
                  }
                  placeholder={SITE_MANAGEMENT_CONSTANTS.PASSWORD_PLACEHOLDER}
                  value={props.values?.password}
                />
                <ErrorMessage
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.PASSWORD}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.HOST}`}
                  label={SITE_MANAGEMENT_CONSTANTS.HOST_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.HOST_PLACEHOLDER}
                />
                <ErrorMessage
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.HOST}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  label={SITE_MANAGEMENT_CONSTANTS.PORT_LABEL}
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.PORT}`}
                  type={FORM_CONSTANTS.TEXT}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.PORT_PLACEHOLDER}
                />
                <ErrorMessage
                  name={`${SITE_MANAGEMENT_CONSTANTS.EMAIL_DETAILS}.${SITE_MANAGEMENT_CONSTANTS.PORT}`}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-12">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title">
                {SITE_MANAGEMENT_CONSTANTS.ANALYTICS_HEADER}
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.GOOGLE_ANALYTICS}
                  label={SITE_MANAGEMENT_CONSTANTS.GOOGLE_ANALYTICS_LABEL}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.GOOGLE_ANALYTICS_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.GOOGLE_ANALYTICS}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.FACEBOOK_PIXEL}
                  label={SITE_MANAGEMENT_CONSTANTS.FACEBOOK_PIXEL_LABEL}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.FACEBOOK_PIXEL_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.FACEBOOK_PIXEL}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-12">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title">
                {SITE_MANAGEMENT_CONSTANTS.SOCIAL_MEDIA_HEADER}
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.INSTAGRAM}
                  label={SITE_MANAGEMENT_CONSTANTS.INSTAGRAM_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.INSTAGRAM_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.INSTAGRAM}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.FACEBOOK}
                  label={SITE_MANAGEMENT_CONSTANTS.FACEBOOK_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.FACEBOOK_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.FACEBOOK}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.TWITTER}
                  label={SITE_MANAGEMENT_CONSTANTS.TWITTER_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.TWITTER_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.TWITTER}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.YOUTUBE}
                  label={SITE_MANAGEMENT_CONSTANTS.YOUTUBE_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.YOUTUBE_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.YOUTUBE}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-12">
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title">
                {SITE_MANAGEMENT_CONSTANTS.LINKS_HEADER}
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.MENU_LINK}
                  label={SITE_MANAGEMENT_CONSTANTS.MENU_LINK_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.MENU_LINK_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.MENU_LINK}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_LOGO_LINK}
                  label={SITE_MANAGEMENT_CONSTANTS.SITE_LOGO_LINK_LABEL}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.SITE_LOGO_LINK_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.SITE_LOGO_LINK}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.ADDRESS}
                  label={SITE_MANAGEMENT_CONSTANTS.ADDRESS_LABEL}
                  placeholder={SITE_MANAGEMENT_CONSTANTS.ADDRESS_PLACEHOLDER}
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.ADDRESS}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  name={SITE_MANAGEMENT_CONSTANTS.ADDRESS_LINK}
                  label={SITE_MANAGEMENT_CONSTANTS.ADDRESS_LINK_LABEL}
                  placeholder={
                    SITE_MANAGEMENT_CONSTANTS.ADDRESS_LINK_PLACEHOLDER
                  }
                />
                <ErrorMessage
                  name={SITE_MANAGEMENT_CONSTANTS.ADDRESS_LINK}
                  component={FORM_CONSTANTS.ERROR_PARENT}
                  className={FORM_CONSTANTS.ERROR}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export { SiteGeneralDetails };
