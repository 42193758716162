import React from "react";
import { useField } from "formik";
import { Button, FontIcon, MultiSelect } from "../core-components";
import {
  IconDefinition,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { BUTTON_CONSTANTS, PRODUCTS_CONSTANTS, useAppSelector } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type CustomInputProps = {
  label?: string;
  step?: any;
  min?: any;
  max?: any;
  name: string;
  value?: any;
  type: string;
  tabIndex?: number;
  prependText?: string;
  appendText?: string;
  isPass?: boolean;
  showPass?: boolean;
  setShowPass?: React.Dispatch<React.SetStateAction<boolean>> | any;
  inputClassName?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconClass?: string;
  discountType?: string;
  iconType?: IconDefinition;
  titleCase?: boolean;
  designation?: boolean;
  withDiscount?: boolean;
  maxLength?: number;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandler?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const CustomInput: React.FC<CustomInputProps> = ({
  type,
  prependText,
  appendText,
  label,
  value,
  isPass,
  step,
  min,
  max,
  tabIndex,
  showPass,
  setShowPass,
  isDisabled,
  maxLength,
  isRequired,
  iconClass,
  iconType,
  inputClassName,
  placeholder,
  name,
  discountType,
  onChangeHandler,
  onBlurHandler,
  titleCase,
  designation,
  withDiscount = true,
  ...props
}) => {
  const {
    common: { error },
  } = useAppSelector((state) => state.root);

  const [field, meta] = useField(name);

  const getPlaceholder = () => {
    if (discountType === "Percentage Discount") {
      return PRODUCTS_CONSTANTS.SELLING_DISCOUNT_PERCENTAGE;
    } else if (discountType === "Flat Discount") {
      return PRODUCTS_CONSTANTS.SELLING_DISCOUNT_FLAT;
    } else {
      return "";
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
  };

  const titleCaseHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newValue = value.replace(/(^|\s)\S/g, (letter) =>
      letter.toUpperCase()
    );
    field.onChange({
      target: {
        name: e.target.name,
        value: newValue,
      },
    });
  };
  return (
    <div
      className={`${
        iconType ? "input-group mt-1 " : "form-group"
      } ${inputClassName}`}
    >
      <label aria-labelledby={label}>
        {label}
        {isRequired ? <span className="text-danger">*</span> : ""}
      </label>
      <div className="input-group">
        {appendText ? (
          <div className="input-group-append">
            <div className="input-group-text">
              {appendText ? (
                appendText
              ) : (
                <FontIcon iconClassNames={iconClass} icon={iconType} />
              )}
            </div>
          </div>
        ) : (
          discountType === "Flat Discount" && (
            <div className="input-group-prepend">
              <div className="input-group-text">₹</div>
            </div>
          )
        )}
        <input
          {...field}
          {...props}
          pattern={type === "number" ? "[0-9]*" : undefined}
          data-testid={name}
          tabIndex={tabIndex}
          min={min ? min : 0}
          max={max}
          maxLength={maxLength}
          step={step}
          value={field.value || value}
          type={type}
          disabled={isDisabled}
          onChange={
            titleCase
              ? titleCaseHandler
              : onChangeHandler
              ? onChangeHandler
              : handleChange
          }
          onBlur={onBlurHandler}
          onWheel={(e: any) => {
            e.target.blur();
          }}
          placeholder={
            discountType && withDiscount ? getPlaceholder() : placeholder
          }
          className={`form-control rounded-lg ${
            designation ? "align-self-center mt-1" : ""
          } ${isPass ? "border-right-0" : ""} ${
            inputClassName ? inputClassName : "py-4"
          }`}
        />
        {discountType === "Percentage Discount" && (
          <div className="input-group-append">
            <div className="input-group-text">%</div>
          </div>
        )}
        {isPass ? (
          <div className="input-group-append border-left-0">
            <div className="input-group-text border-left-0 input-group-transparent">
              <Button
                text={
                  <FontAwesomeIcon
                    className={iconClass}
                    icon={showPass ? faEyeSlash : faEye}
                    color="#007bff"
                  />
                }
                type={BUTTON_CONSTANTS.BUTTON}
                btnClassNames="btn btn-transparent p-0  outline-none border-0"
                onClickHandler={() => {
                  setShowPass(!showPass);
                }}
              />
            </div>
          </div>
        ) : null}
        {iconType || iconClass || prependText ? (
          <div className="input-group-append">
            <div className="input-group-text">
              {prependText ? (
                prependText
              ) : (
                <FontIcon iconClassNames={iconClass} icon={iconType} />
              )}
            </div>
          </div>
        ) : null}
      </div>
      {error === name && <div className="text-danger">{error}</div>}
    </div>
  );
};
export { CustomInput };
