import { faRightLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  Button,
  FontIcon,
  Loader,
  RefundModal,
  Table,
  TableBody,
  TableHeader,
} from "components";
import React, { useMemo, useState } from "react";
import { setCustomerToEdit } from "store/ManageCustomers/customerSlice";
import { setCurrentReturn } from "store/POS";
import {
  GENERAL_CONSTANTS,
  IGetOrder,
  IReturnItem,
  ORDER_CONSTANTS,
  POS_CONSTANTS,
  STATUSES,
  calculateProductsInProductGroup,
  displayValue,
  to2Decimal,
  useAppDispatch,
  useAppSelector,
} from "utils";

interface IProps {
  orderDetails: IGetOrder;
  refundItems: IReturnItem[];
  setRefundItems: React.Dispatch<React.SetStateAction<IReturnItem[]>>;
}

const OrderProductDetails = ({
  orderDetails,
  refundItems,
  setRefundItems,
}: IProps) => {
  const dispatch = useAppDispatch();
  const {
    cart: { return: returnRefunds, returnOrder: returnOrderDetails },
    order: { status: orderStatus },
  } = useAppSelector((state) => state.root);

  const [refundModal, setRefundModal] = useState(false);

  const tableCols = [
    "#",
    "Product Code",
    "Product Name",
    "Quantity",
    "MRP",
    "Discount",
    "Tax",
    "Unit Cost",
    "Net Amount",
    returnRefunds ? "Return quantity" : null,
    returnRefunds ? "Action" : null,
  ];

  const findReturnProduct = (currentReturn) => {
    const test = refundItems.find((cartItem: any) => {
      // if (cartItem.batch === currentReturn.batch.id) {
      //   if (currentReturn.product_group !== null) {
      //     if (
      //       cartItem?.product_group?.id === currentReturn?.product_group?.id
      //     ) {
      //       return cartItem;
      //     }
      //   } else if (cartItem.product_group === null) {
      //     return cartItem;
      //   }
      // } else {
      //   return false;
      // }
      return cartItem.order_product_id === currentReturn.id;
    });

    return test !== undefined && test;
  };

  const remainingProductsAfterReturn = useMemo(() => {
    const returnedProducts = [];

    for (let i = 0; i < returnOrderDetails?.returns?.length; i++) {
      for (
        let j = 0;
        j < returnOrderDetails?.returns[i]?.products?.length;
        j++
      ) {
        if (returnOrderDetails.returns[i].credit_type !== "advance") {
          returnedProducts.push(returnOrderDetails?.returns[i]?.products[j]);
        }
      }
    }

    const groupedItems = returnedProducts.reduce((acc, item) => {
      const key = `${item.batch.id}-${
        item.product_group ? item.product_group.id : ""
      }`;
      if (!acc[key]) {
        acc[key] = { ...item, quantity: 0 };
      }
      acc[key].quantity += item.quantity;
      return acc;
    }, {});

    const finalArray = Object.values(groupedItems) as any[];

    const updatedProducts = [];

    for (const i in returnOrderDetails.products) {
      const product = returnOrderDetails.products[i];
      const returnedProduct = finalArray.find((item) => {
        if (item.batch.id === product.batch.id) {
          if (product.product_group !== null) {
            if (product.product_group.id === item?.product_group?.id) {
              return item;
            } else if (item.product_group === null) {
              return item;
            }
          } else if (product.product_group === null) {
            if (item.product_group === null) {
              return item;
            }
          }
        }
      });

      if (returnedProduct) {
        if (product.quantity - returnedProduct.quantity) {
          updatedProducts.push({
            ...product,
            quantity: product.quantity - returnedProduct.quantity,
            returned: true,
            originalQuantity: product.quantity,
          });
        }
      } else {
        updatedProducts.push(product);
      }
    }

    return updatedProducts;
  }, [returnOrderDetails?.returns?.length]);

  const groupBy = (input, key) => {
    return input.reduce((acc, currentValue) => {
      let groupKey = currentValue[key]?.hamper_code;
      if (!groupKey) {
        groupKey = "undefined";
      }
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {});
  };

  const filterCartItems = (cartItems: any[]) => {
    if (cartItems?.length > 0) {
      const groupedCartItems = groupBy(cartItems, "product_group");
      return Object.entries(groupedCartItems);
    } else {
      return [];
    }
  };

  const calculateDiscount = (orderItem: any) => {
    return orderItem.discount_value > 0
      ? orderItem.discount_type === GENERAL_CONSTANTS.PERCENTAGE
        ? (to2Decimal(orderItem.total_amount / orderItem.quantity) *
            orderItem.discount_value) /
          100
        : to2Decimal(orderItem.total_amount / orderItem.quantity) -
          orderItem.discount_value
      : 0;
  };

  const calculateValues = (productGroup: any[]) => {
    const total_amount = productGroup.reduce((acc, currentValue) => {
      return (
        acc + to2Decimal(currentValue.total_amount / currentValue.quantity)
      );
    }, 0);

    const total_tax = productGroup.reduce((acc, currentValue) => {
      return acc + currentValue.total_tax;
    }, 0);
    const payable_amount = productGroup.reduce((acc, currentValue) => {
      return acc + currentValue.payable_amount;
    }, 0);

    const total_discount = productGroup.reduce((acc, currentValue) => {
      return acc + calculateDiscount(currentValue);
    }, 0);
    return { total_amount, total_tax, payable_amount, total_discount };
  };

  const discount_to_show = (item) => {
    return item.discount_value > 0
      ? item.discount_type === GENERAL_CONSTANTS.PERCENTAGE
        ? `${to2Decimal(item.discount_value)}% (${
            POS_CONSTANTS.RUPEE_SIGN
          }${displayValue(
            to2Decimal((item.total_amount * item.discount_value) / 100)
          )})`
        : `${POS_CONSTANTS.RUPEE_SIGN}${displayValue(
            to2Decimal(item.discount_value)
          )}`
      : "-";
  };

  const displayOrderItems = (cartItems: any[]) => {
    if (orderStatus === STATUSES.LOADING) {
      return (
        <tr>
          <td colSpan={tableCols.length} className="text-center">
            <Loader small={true} />
          </td>
        </tr>
      );
    } else {
      const filterItems = filterCartItems(orderDetails?.products).map(
        (item: any) => {
          return item[0] !== "undefined" && item[1];
        }
      );

      return cartItems?.length > 0 ? (
        <React.Fragment>
          {filterCartItems(orderDetails?.products).map(
            (item: any, productGroupIndex) => {
              const productGroupDetails = item[1][0];
              const {
                total_amount,
                total_tax,
                payable_amount,
                total_discount,
              } = calculateValues(item[1]);
              return (
                item[0] !== "undefined" && (
                  <React.Fragment key={productGroupIndex}>
                    <tr
                      key={productGroupIndex}
                      // className="accordion-item"
                      data-bs-toggle="collapse"
                      data-bs-target={`.pg${productGroupIndex}`}
                    >
                      <td>{productGroupIndex + 1}</td>
                      <td>{productGroupDetails?.product_group?.hamper_code}</td>
                      <td>
                        {productGroupDetails?.product_group?.print_hamper_name}
                      </td>
                      <td>{productGroupDetails?.count}</td>
                      <td>
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(total_amount)}
                      </td>
                      <td>
                        {total_discount > 0
                          ? `${POS_CONSTANTS.RUPEE_SIGN}${displayValue(
                              total_discount
                            )}`
                          : "-"}
                      </td>
                      <td>
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(total_tax)}
                      </td>
                      <td>N/A</td>
                      <td>
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(payable_amount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12} className="p-0">
                        <table
                          className={`table table-fixed pg${productGroupIndex} accordion-collapse collapse mb-0`}
                        >
                          <tbody>
                            {item[1].map((orderItem: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td width={"5%"} className="pl-4">
                                    {index + 1}
                                  </td>
                                  <td width={"15%"}>
                                    {orderItem?.product?.product_code}
                                  </td>
                                  <td width={"20%"}>
                                    {orderItem?.product?.print_name}
                                  </td>
                                  <td width={"10%"} className="pl-4">
                                    {orderItem?.quantity}
                                  </td>
                                  <td width={"10%"} className="pl-4">
                                    {POS_CONSTANTS.RUPEE_SIGN}
                                    {to2Decimal(
                                      orderItem?.total_amount /
                                        orderItem?.quantity
                                    )}
                                  </td>
                                  <td width={"10%"}>
                                    {discount_to_show(orderItem)}
                                  </td>
                                  <td width={"10%"}>
                                    {POS_CONSTANTS.RUPEE_SIGN}
                                    {orderItem?.total_tax}
                                  </td>
                                  <td>
                                    {POS_CONSTANTS.RUPEE_SIGN}
                                    {to2Decimal(
                                      orderItem?.payable_amount /
                                        orderItem?.quantity
                                    )}
                                  </td>
                                  <td>
                                    {POS_CONSTANTS.RUPEE_SIGN}
                                    {orderItem?.payable_amount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* <Table
                          tableType="mb-0"
                          tableClassNames={`pg${productGroupIndex} accordion-collapse collapse`}
                        >
                          <TableBody>
                            
                          </TableBody>
                        </Table> */}
                      </td>
                    </tr>
                  </React.Fragment>
                )
              );
            }
          )}
          {filterCartItems(orderDetails?.products).map(
            (item: any, cartItemIndex) => {
              if (item[0] === "undefined") {
                return item[1].map((orderItem: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + filterItems.length}</td>
                      <td className="text-wrap">
                        {orderItem?.product?.product_code}
                      </td>
                      <td className="text-wrap">
                        {orderItem?.product?.print_name}
                      </td>
                      <td>{orderItem?.quantity}</td>
                      <td>
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(
                          to2Decimal(
                            orderItem.total_amount / orderItem.quantity
                          )
                        )}
                      </td>
                      <td>
                        <div className="col-4 text-end">
                          {discount_to_show(orderItem)}
                        </div>
                      </td>
                      <td>
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(orderItem?.total_tax)}
                      </td>
                      <td>
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(
                          to2Decimal(
                            orderItem?.payable_amount / orderItem?.quantity
                          )
                        )}
                      </td>
                      <td>
                        {POS_CONSTANTS.RUPEE_SIGN}
                        {displayValue(orderItem?.payable_amount)}
                      </td>
                    </tr>
                  );
                });
              }
            }
          )}
        </React.Fragment>
      ) : null;
    }
  };

  return (
    <div className="d-flex flex-row px-0">
      <Table>
        <TableHeader cols={tableCols} tableHeaderClassNames="table-dark" />
        <TableBody>
          {!returnRefunds ? (
            orderDetails && displayOrderItems(orderDetails?.products)
          ) : returnOrderDetails &&
            Object.keys(returnOrderDetails).length > 0 &&
            remainingProductsAfterReturn.length > 0 ? (
            remainingProductsAfterReturn?.map((orderItem, index) => {
              return orderItem.quantity !== 0 ? (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="text-wrap">
                    {orderItem?.product?.product_code}
                  </td>
                  <td className="text-wrap">
                    {orderItem?.product?.print_name}
                  </td>
                  <td>{orderItem?.quantity}</td>
                  <td>
                    {POS_CONSTANTS.RUPEE_SIGN}
                    {displayValue(
                      to2Decimal(
                        orderItem.returned
                          ? orderItem?.total_amount /
                              orderItem?.originalQuantity
                          : orderItem?.total_amount / orderItem?.quantity
                      )
                    )}
                  </td>
                  <td>{discount_to_show(orderItem)}</td>
                  <td>
                    {POS_CONSTANTS.RUPEE_SIGN}
                    {orderItem?.total_tax}
                  </td>
                  <td>
                    {POS_CONSTANTS.RUPEE_SIGN}
                    {displayValue(
                      to2Decimal(
                        orderItem.returned
                          ? orderItem?.payable_amount /
                              orderItem?.originalQuantity
                          : orderItem?.payable_amount / orderItem?.quantity
                      )
                    )}
                  </td>
                  <td>
                    {POS_CONSTANTS.RUPEE_SIGN}
                    {displayValue(
                      to2Decimal(
                        orderItem.returned
                          ? (orderItem?.payable_amount /
                              orderItem.originalQuantity) *
                              orderItem?.quantity
                          : orderItem?.payable_amount
                      )
                    )}
                  </td>
                  <td>
                    {displayValue(
                      to2Decimal(
                        findReturnProduct(orderItem)
                          ? findReturnProduct(orderItem).quantity
                          : 0
                      )
                    )}
                  </td>
                  <td>
                    <Button
                      text={<FontIcon icon={faRightLeft} />}
                      // isDisabled={findReturnProduct(orderItem)?.quantity === 0}
                      btnClassNames={"btn btn-secondary btn-sm"}
                      type="button"
                      onClickHandler={() => {
                        setRefundModal(true);
                        dispatch(setCustomerToEdit(orderDetails.customer));
                        dispatch(setCurrentReturn(orderItem));
                      }}
                    />
                  </td>
                </tr>
              ) : null;
            })
          ) : (
            <tr>
              <td colSpan={tableCols.length} className="text-center">
                {ORDER_CONSTANTS.NO_ITEMS_MESSAGE}
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
      {refundModal && (
        <RefundModal
          modal={refundModal}
          setModal={setRefundModal}
          refundItems={refundItems}
          setRefundItems={setRefundItems}
        />
      )}
    </div>
  );
};

export { OrderProductDetails };
